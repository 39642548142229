/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Image, Button } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotogalerie"}>
        <SiteHeader />

        <Column className="pb--0 pt--80" name={"wstęp"}>
          
          <ColumnWrap className="column__flex el--1 pb--0 pt--60" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Galeria"}>
              </Title>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--30 pt--80" name={"galeria-1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left pb--20 pt--20">
              
              <Subtitle className="subtitle-box subtitle-box--style8 subtitle-box--right" content={"Konstrukcja ścian"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"galeria-2"} layout={"l8"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":""}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/97/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/97/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/97/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/97/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/97/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/97/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--30 pt--80" name={"galeria-3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left pb--20 pt--20">
              
              <Subtitle className="subtitle-box subtitle-box--style8 subtitle-box--right" content={"Betonowanie "}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"galeria-4"} layout={"l8"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":""}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/97/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/97/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/97/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/97/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/97/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/97/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/97/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/97/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/97/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/97/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/97/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--parallax pb--80 pt--80" name={"kontakt"} parallax={true}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4 pb--80 pt--80" anim={"7"} animS={"4"}>
              
              <Title className="title-box" content={"Od chodników po fasady."}>
              </Title>

              <Button className="btn-box swpf--uppercase" href={"/pl/kontakt"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-szp6dj --parallax pb--80 pt--80" name={"stopka"} layout={"l1"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/97/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --left el--3 pb--20 pt--20 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: white;\">Domaniewski</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje.</span>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\"><a href=\"https://saywebpage.com\">Tworzenie stron</a></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: white;\">Kontakt</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":269}} content={"<span style=\"color: white;\">Pańska 18, Warszawa<br>+48 797 811 2X0<br>info@twoje-stronyy.com</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Usługi / Cennik</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Galeria</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Kontakt</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--right fs--24" content={"<span style=\"color: white;\">Powrót do góry</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}